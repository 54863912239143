import React from "react";
import "../../styles/follow/FollowExperiencePage.module.css";
import { FollowCard } from "./FollowCard";
import styles from "../../styles/follow/FollowExperiencePage.module.css";
import { Heading, PreferencesNoticeUi } from "~/components";

const cards = [
  {
    title: "Golf",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/mc-wasbd-golf-course-35141?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/rz-okarz-golf-ball-20922?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/ak-nascv-nascv-golf-aerial-2-24070?wid=316&fit=constrain",
    ],
    category: "GOLF_LOV",
    value: "ENTHU",
    passion: "golf",
    analyticsDesc: "Golf",
  },
  {
    title: "Beach & Water Sports",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/si-nanti-snorkeling-35248?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/tx-miatw-beach-bliss-12961?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/stock-tours-1073191?wid=316&fit=constrain",
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "BCH",
    passion: "beach",
    analyticsDesc: "Beach",
  },
  {
    title: "Mountain & Winter Sports",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/tx-szgjf-skiing-31248?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/50551093-201803_nst_elledge_family_ski_school001?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/ski-slope-breckenridge-colorado?wid=316&fit=constrain",
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "WNTRACT",
    passion: "ski",
    analyticsDesc: "Ski",
  },
  {
    title: "Culinary",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/fp-aklfp-wine-dinner-20235?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/si-yyztc-food-drinks-38936?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/cy-laxmp-laxmp-chef-37142?wid=316&fit=constrain",
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "COOK",
    passion: "culinary",
    analyticsDesc: "Culinary",
  },
  {
    title: "Arts & Culture",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/cy-sfobc-cable-car-with-alcatraz-23185?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/ak-franv-alte-oper-36128-96740?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/bonvoy-gettyimages-1432866467-14974?wid=316&fit=constrain",
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "CULTR",
    passion: "culture",
    analyticsDesc: "Culture",
  },
  {
    title: "Live Music & Concerts",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/mc-tricc-jazz-musicians-23095?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/br-nycrt-enjoy-a-concert-at-msg-20887?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/md-wasrl-getty-concert-639920934-38114?wid=316&fit=constrain",
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "MUSIC",
    passion: "music",
    analyticsDesc: "Music",
  },
  {
    title: "Spa",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/br-sjubr-elemara-spa-38009-22237?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/xr-ejhxr-the-st-regis-spa---p40130-33489?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/50514591-espa_privatespasuite_outdoor_sized_tile?wid=316&fit=constrain",
    ],
    category: "SPA_LOV",
    value: "ENTHU",
    passion: "spa",
    analyticsDesc: "Spa",
  },
];

export const FollowExperiencePage = () => {
  return (
    <>
      <Heading type="h1" text="Follow Your Passions" />
      <p className={styles.subheader}>
        Discover new places and experiences that inspire your next adventure and
        ignite your passions.
      </p>
      <div className={styles.list}>
        {cards.map((card) => (
          <FollowCard
            key={card.title}
            title={card.title}
            images={card.images}
            category={card.category}
            value={card.value}
            passion={card.passion}
            analyticsDesc={card.analyticsDesc}
          />
        ))}
      </div>
      <PreferencesNoticeUi className={styles.notice} />
    </>
  );
};
