import React from "react";

export const CulinaryIcon = (props) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.8333 8.33325V37.4999H29.1667V8.33325H37.5V37.4999H45.8333V8.33325H54.1667V33.3333C54.1667 42.5416 46.7083 49.9999 37.5 49.9999V91.6666H29.1667V49.9999C19.9583 49.9999 12.5 42.5416 12.5 33.3333V8.33325H20.8333ZM87.5 8.33325V91.6666H79.1667V62.4999H62.5V24.9999C62.5 13.7311 74.3828 8.59273 86.5627 8.34285L87.5 8.33325ZM79.1667 17.6291L79.0948 17.6459C74.1271 18.9605 71.1738 21.4795 70.861 24.4678L70.8333 24.9999V54.1666H79.1667V17.6291Z"
      fill="currentColor"
    />
  </svg>
);
