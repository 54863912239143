import React from "react";
import { useSSORedirect } from "~/hooks";
import { BtnUi, Heading, DialogUi, Icon } from "~/components";
import styles from "./SignInDialogUi.module.css";

export const SignInDialogUi = ({
  onClose,
  onSubmit: onSubmitCb,
  selectedPassions,
}) => {
  const ssoRedirect = useSSORedirect();
  const onSubmit = () => {
    ssoRedirect();
    onSubmitCb();
  };

  return (
    <DialogUi
      onClose={onClose}
      header={
        <div className={styles.passions}>
          {selectedPassions.slice(0, 4).map((passion, i) => (
            <Icon key={i} name={passion} className={styles.passion} />
          ))}
        </div>
      }
      body={
        <>
          <Heading
            type="h2"
            className={styles.heading}
            text="Sign In to Save Your Selections"
          />
          <p className={styles.text}>
            Sign in to your Marriott Bonvoy profile to save your selected
            passions.
          </p>
        </>
      }
      footer={
        <BtnUi variant="primary" block onClick={onSubmit}>
          Sign In
        </BtnUi>
      }
    />
  );
};
