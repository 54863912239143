import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getCookie } from "../utils/utils";
import LoadingPage from "./LoadingPage";
import { NotFoundPage } from "../pages";

export default function TryAuth() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const [hasAuthCode, setHasAuthCode] = useState(!!code);

  useEffect(() => {
    if (!hasAuthCode) {
      return;
    }

    const newUrl = `${window.location.pathname}`;
    window.history.replaceState(null, "", newUrl);

    const getToken = async () => {
      const tokenResponse = await fetch(
        "/api/v1/web/marriott-passions-v3/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code, state }),
        },
      );

      if (!tokenResponse.ok) {
        setHasAuthCode(false);
        return;
      }

      const body = await tokenResponse.json();

      if (!body.acid) {
        setHasAuthCode(false);
        return;
      }

      const campaign = getCookie("passions_campaign");
      const jumpToResult = getCookie("jtr");

      if (!campaign) {
        navigate(`/campaign?acid=${body.acid}`);
        return;
      }

      if (jumpToResult) {
        sessionStorage.setItem("acid", body.acid);
      }

      const page = jumpToResult
        ? new URLSearchParams(campaign).get("expId") + "/explore"
        : `/campaign?acid=${body.acid}&${campaign}`;

      navigate(page);
    };

    getToken();
  }, []);

  if (hasAuthCode) {
    return <LoadingPage />;
  }

  return <NotFoundPage />;
}
