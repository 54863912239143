import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function getAcidFromLocation(location) {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get("acid");
}

function getAcidFromCookie(cookieName) {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === cookieName) {
      try {
        const decodedCookieValue = decodeURIComponent(value);
        if (decodedCookieValue) {
          return decodedCookieValue;
        } else {
          console.log("dtm_user_id cookie value is empty");
          return null;
        }
      } catch (error) {
        console.error("Failed to parse dtm_user_id cookie:", error);
        return null;
      }
    }
  }
  return null;
}

export const useAcid = () => {
  const location = useLocation();
  const acidFromLocation = getAcidFromLocation(location);
  const acid =
    acidFromLocation ||
    getAcidFromCookie("dtm_user_id") ||
    sessionStorage.getItem("acid");

  const authState = "anonymous";
  if (acid) {
    authState = "remembered";
    if (!acidFromLocation) {
      authState = getAcidFromCookie("s_loginState") || authState;
    }
  }

  useEffect(() => {
    const acidFromStorage = sessionStorage.getItem("acid");
    if (acidFromStorage !== acid) {
      sessionStorage.setItem("acid", acid);
    }
  }, [acid]);

  return { acid, authState };
};
