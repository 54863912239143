import React from "react";

export const MusicIcon = (props) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M88 22.0493C88 15.7879 82.3112 11.0666 76.1571 12.2205L39.9071 19.0174C35.1774 19.9042 31.75 24.034 31.75 28.8461V67.4414C29.8945 66.355 27.7949 65.6836 25.5 65.6836C18.6274 65.6836 13 71.311 13 78.1836C13 85.0562 18.6274 90.6836 25.5 90.6836C32.3726 90.6836 38 85.0562 38 78.1836V43.2227L81.75 35.0195V58.0664C79.8945 56.98 77.7949 56.3086 75.5 56.3086C68.6274 56.3086 63 61.936 63 68.8086C63 75.6812 68.6274 81.3086 75.5 81.3086C82.3726 81.3086 88 75.6812 88 68.8086V22.0493ZM74.6443 18.8519C78.3368 18.1595 81.75 20.9923 81.75 24.7491V28.7695L38 36.9727V30.7022C38 27.8149 40.0564 25.3371 42.8943 24.805L74.6443 18.8519ZM75.5 62.5586C78.9912 62.5586 81.75 65.3174 81.75 68.8086C81.75 72.2998 78.9912 75.0586 75.5 75.0586C72.0088 75.0586 69.25 72.2998 69.25 68.8086C69.25 65.3174 72.0088 62.5586 75.5 62.5586ZM25.5 71.9336C28.9912 71.9336 31.75 74.6924 31.75 78.1836C31.75 81.6748 28.9912 84.4336 25.5 84.4336C22.0088 84.4336 19.25 81.6748 19.25 78.1836C19.25 74.6924 22.0088 71.9336 25.5 71.9336Z"
      fill="currentColor"
    />
  </svg>
);
