import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingPage from "../LoadingPage";
import { NotFoundPage } from "~/pages";

function ChooseCampaign() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    // resetting values once URL parameters have changed
    setLoaded(false);

    console.log("Retrieving Campaign parameters from the request.");
    const searchParams = new URLSearchParams(location.search);
    const cSourceValue = searchParams.getAll("cSource");
    const cidValue = searchParams.getAll("expId");
    const testParamValue = searchParams.get("test");
    const channelSrcValue = searchParams.get("channelSrc");
    const acidValueRequest = searchParams.get("acid");

    console.log("Value of 'cSource' param from the request: " + cSourceValue);
    console.log("Value of 'cid' param from the request: " + cidValue);

    if (cSourceValue && cidValue) {
      // Generate a random number from 0 - 10000
      const randNum = Math.floor(Math.random() * 10000);
      const expCnt = cidValue.length;

      const parseNum = Math.ceil(10000 / expCnt);
      let tempParseNum = Math.ceil(10000 / expCnt);

      console.log("Random: " + randNum);
      console.log("expCnt: " + expCnt);
      console.log("parsenum: " + parseNum);

      // Find the index of the cid to use based on the random number
      let expIdx = 0;
      let found = false;
      while (expIdx < expCnt && !found) {
        if (randNum > tempParseNum) {
          expIdx += 1;
        } else {
          found = true;
        }
        tempParseNum += parseNum;
      }

      let cSourceUrl = "marriott";

      // Build the URL
      let destinationUrl =
        "/campaign" + "?cSource=" + cSourceUrl + "&cid=" + cidValue[expIdx];
      if (acidValueRequest) {
        destinationUrl += "&acid=" + acidValueRequest;
      }
      if (channelSrcValue) {
        destinationUrl += "&channelSrc=" + channelSrcValue;
      }
      if (testParamValue) {
        destinationUrl += "&test=" + testParamValue;
      }

      navigate(destinationUrl);
      return;
    } else {
      console.error("Invalid or missing required parameters: cSource or cid.");
    }

    setLoaded(true);
  }, [location.search]);

  if (!isLoaded) {
    return <LoadingPage />;
  }

  return <NotFoundPage trackValue="Campaign Not Found Page|Home Button" />;
}

export default ChooseCampaign;
