import { useLocation } from "react-router-dom";
import { setCookie } from "~/utils/utils";

export const useSSORedirect = (jumpToResult = true) => {
  const { search } = useLocation();

  return () => {
    const searchParams = new URLSearchParams(search);
    const queryParams = searchParams.toString();
    const apiUrl = "/api/v1/web/marriott-passions-v3/login";
    setCookie("passions_campaign", queryParams, 10);

    if (jumpToResult) {
      setCookie("jtr", true, 10);
    }

    window.location.href = apiUrl;
  };
};
