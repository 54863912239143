export const data = {
  golf: {
    slides: [
      {
        headline: "Popular Golf Resorts",
        label: "Find beautiful places to stay and play.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/ahnrz-golf-course-50669716?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/resorts/golf-resorts",
        analyticsDesc: "Golf Resort",
      },
    ],
  },
  beach: {
    slides: [
      {
        headline: "Discover Beach Resorts",
        label: "Seek sun and sand at these dreamy destinations.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/xr-mlexr-whale-bar-aerial-37919-47743?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/resorts/beach-resorts",
        analyticsDesc: "Beach Resort",
      },
    ],
  },
  ski: {
    slides: [
      {
        headline: "Top Ski Resorts",
        label: "Seek snowy peaks at these amazing hotels.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/whistler-village-whistler-british-columbia-canada?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/resorts/ski-resorts",
        analyticsDesc: "Ski Resort",
      },
    ],
  },
  culinary: {
    slides: [
      {
        headline: "Culinary Experiences",
        label: "Find global dining destinations for every taste.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/si-yyztc-food-drinks-38936?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/dining",
        analyticsDesc: "Wine and Dine",
      },
    ],
  },
  culture: {
    slides: [
      {
        headline: "Marriott Bonvoy Moments™",
        label:
          "Attend premiers, galas, cultural excursions and exclusive events.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/bonvoy-gettyimages-1432866467-14974?wid=316&fit=constrain",
        url: "https://moments.marriottbonvoy.com/en-us/moments/category/arts-lifestyle",
        analyticsDesc: "Local Experiences",
      },
    ],
  },
  music: {
    slides: [
      {
        headline: "Marriott Bonvoy Moments™",
        label:
          "Get show-stopping access to epic concerts, music festivals and more.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/br-nycrt-enjoy-a-concert-at-msg-20887?wid=316&fit=constrain",
        url: "https://moments.marriottbonvoy.com/en-us/moments/category/entertainment",
        analyticsDesc: "Moments",
      },
    ],
  },
  spa: {
    slides: [
      {
        headline: "Spa Resorts",
        label: "Prioritize rest and relaxation at these retreats.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/br-sjubr-elemara-spa-38009-22237?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/resorts/spa-resorts",
        analyticsDesc: "Luxury Spa",
      },
    ],
  },
};
