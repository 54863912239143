import React from "react";

export const SkiIcon = (props) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M77.0834 18.7501C77.0834 23.3334 73.3334 27.0834 68.75 27.0834C64.1667 27.0834 60.4167 23.3334 60.4167 18.7501C60.4167 14.1667 64.1667 10.4167 68.75 10.4167C73.3334 10.4167 77.0834 14.1667 77.0834 18.7501ZM65.75 87.0834L68.9167 88.2084C71.5 89.0834 74.2084 89.5834 77.0834 89.5834C80.5834 89.5834 83.9584 88.8334 87 87.5001L91.6667 92.2084C87.2917 94.5001 82.3334 95.8334 77.0834 95.8334C73.5 95.8334 70.0834 95.2501 66.875 94.1251L8.33337 72.7917L10.4167 66.9167L39.1667 77.3751L46.3334 58.8751L31.4584 43.3334C27.75 39.4167 28.6667 33.0417 33.3334 30.3334L47.8334 21.9584C52.4167 19.2917 58.3334 21.5417 59.9584 26.5834L61.3334 31.0834C63.1667 37.0001 67.5 41.7917 73.125 44.1667L75.2917 37.5001L81.25 39.4167L76.5834 53.7917C66.375 52.1251 57.9167 45.1667 54.1667 35.7917L43.625 41.8334L56.25 56.2501L47 80.2501L59.875 84.9167L68.625 58.0834C70.5417 58.8334 72.5417 59.3751 74.625 59.7917L65.75 87.0834Z"
      fill="currentColor"
    />
  </svg>
);
