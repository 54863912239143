import React from "react";

export const SpaIcon = (props) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M64.5416 40.1249C63.7916 28.4999 59.0833 17.1666 50.2499 8.33325C41.3333 17.2499 36.4166 28.5833 35.4583 40.1249C40.7916 42.9583 45.7083 46.6249 49.9999 51.0833C54.2916 46.6666 59.2083 42.9999 64.5416 40.1249ZM50.2083 21.6249C52.8333 25.9166 54.6666 30.7083 55.6249 35.7083C53.6666 36.9583 51.8333 38.3333 50.0416 39.7916C48.2916 38.3749 46.4166 36.9999 44.4999 35.7499C45.5416 30.7499 47.4583 25.9583 50.2083 21.6249ZM49.9999 64.3749C46.5833 59.1666 42.2499 54.6249 37.2499 51.0416C36.7083 50.6666 36.1249 50.3749 35.5833 49.9583C36.1249 50.3333 36.7083 50.6666 37.2083 50.9999C29.0833 45.1249 19.1249 41.6666 8.33325 41.6666C8.33325 63.8333 22.3333 82.5833 41.7916 89.5416C44.4166 90.4999 47.1666 91.2083 49.9999 91.6666C52.8333 91.1666 55.5416 90.4583 58.2083 89.5416C77.6666 82.5833 91.6666 63.8333 91.6666 41.6666C74.2499 41.6666 58.9583 50.7083 49.9999 64.3749ZM55.4999 81.6666C53.6666 82.2916 51.8333 82.7916 49.9583 83.2083C48.1249 82.8333 46.3333 82.3333 44.6249 81.7083C30.9166 76.7916 20.8749 65.0833 17.7499 51.0833C22.3333 52.1666 26.7083 54.0416 30.7499 56.6249L30.6666 56.6666C31.2083 57.0416 31.7499 57.4166 32.2916 57.7083L32.5833 57.8749C36.7083 60.8749 40.2499 64.5833 43.0416 68.9166L49.9999 79.5833L56.9583 68.9583C59.8333 64.5833 63.4166 60.8333 67.4999 57.8749L67.7916 57.6666C68.1666 57.4583 68.5416 57.2083 68.9166 56.9583L68.8749 56.8749C72.9583 54.1666 77.4999 52.1666 82.2499 51.0416C79.1249 65.0833 69.1249 76.7916 55.4999 81.6666Z"
      fill="currentColor"
    />
  </svg>
);
