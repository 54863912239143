import React from "react";

export const GolfIcon = (props) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M70.8333 70.8333V79.1666H62.4999C57.9166 79.1666 54.1666 82.9166 54.1666 87.4999V91.6666H45.8333V87.4999C45.8333 82.9166 42.0833 79.1666 37.4999 79.1666H29.1666V70.8333H70.8333ZM49.9999 8.33325C66.1249 8.33325 79.1666 21.3749 79.1666 37.4999C79.1666 53.6249 66.1249 66.6666 49.9999 66.6666C33.8749 66.6666 20.8333 53.6249 20.8333 37.4999C20.8333 21.3749 33.8749 8.33325 49.9999 8.33325ZM49.9999 16.6666C38.4999 16.6666 29.1666 25.9999 29.1666 37.4999C29.1666 48.9999 38.4999 58.3333 49.9999 58.3333C61.4999 58.3333 70.8333 48.9999 70.8333 37.4999C70.8333 25.9999 61.4999 16.6666 49.9999 16.6666ZM41.6666 29.1666C43.9678 29.1666 45.8333 31.0321 45.8333 33.3333C45.8333 35.6344 43.9678 37.4999 41.6666 37.4999C39.3654 37.4999 37.4999 35.6344 37.4999 33.3333C37.4999 31.0321 39.3654 29.1666 41.6666 29.1666ZM58.3333 29.1666C60.6344 29.1666 62.4999 31.0321 62.4999 33.3333C62.4999 35.6344 60.6344 37.4999 58.3333 37.4999C56.0321 37.4999 54.1666 35.6344 54.1666 33.3333C54.1666 31.0321 56.0321 29.1666 58.3333 29.1666ZM49.9999 20.8333C52.3011 20.8333 54.1666 22.6987 54.1666 24.9999C54.1666 27.3011 52.3011 29.1666 49.9999 29.1666C47.6987 29.1666 45.8333 27.3011 45.8333 24.9999C45.8333 22.6987 47.6987 20.8333 49.9999 20.8333Z"
      fill="currentColor"
    />
  </svg>
);
