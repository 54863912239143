import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from "./Footer";
import { useLayoutStore } from "~/store";
import logo from "../../assets/images/Marriott-Logo-Path-Connector.png";
import styles from "./TitleBarLayout.module.css";

export const TitleBarLayout = ({ children }) => {
  const {
    showHeader,
    showFooter,
    contentClassName,
    resetLayoutStore,
    completeReset,
  } = useLayoutStore();

  const location = useLocation();
  const prevLocationRef = useRef(location);

  // Reset layout store on each route change
  useEffect(() => {
    const { pathname, search } = prevLocationRef.current;
    const pathnameChanged = location.pathname !== pathname;
    const searchChanged = location.search !== search;

    if (pathnameChanged || searchChanged) {
      prevLocationRef.current = location;
      resetLayoutStore();
      queueMicrotask(() => completeReset());
    }
  }, [location]);

  return (
    <div className={styles.container}>
      {showHeader && (
        <header className={styles.header}>
          <img alt="Marriott logo" src={logo} className={styles.logo} />
        </header>
      )}
      <div className={contentClassName || styles.content}>{children}</div>
      {showFooter && <Footer />}
    </div>
  );
};
