import React from "react";
import { FollowExperiencePage } from "../follow/FollowExperiencePage";
import { BubblePage } from "../share/BubblePage";
import { VisualPage } from "../visual/VisualPage";
import { DerivedVisualPage } from "../derived-visual/DerivedVisualPage";
import { VideoPassions } from "../../pages/CampaignPage/VideoPassions";
import { NotFoundPage } from "~/pages";
import styles from "./RenderCampaign.module.css";

function getExperience(expId) {
  switch (expId) {
    case "follow":
      return <FollowExperiencePage />;
    case "share":
      return <BubblePage />;
    case "travel":
      return <VisualPage />;
    case "golf":
      return <DerivedVisualPage />;
    case "video":
      return <VideoPassions />;
    default:
      return null;
  }
}

export function MarriottCampaignData({ expId, showNotFound }) {
  if (!showNotFound) {
    const experience = getExperience(expId);
    if (experience) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.wrapper}>{experience}</div>
        </div>
      );
    }
  }

  return <NotFoundPage trackValue="Campaign Not Found Page|Home Button" />;
}
