import React from "react";

export const CultureIcon = (props) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M50 8.33325C27 8.33325 8.33337 26.9999 8.33337 49.9999C8.33337 72.9999 27 91.6666 50 91.6666C73 91.6666 91.6667 72.9999 91.6667 49.9999C91.6667 26.9999 73 8.33325 50 8.33325ZM16.6667 49.9999C16.6667 47.4583 17 44.9583 17.5417 42.5833L37.4584 62.4999V66.6666C37.4584 71.2499 41.2084 74.9999 45.7917 74.9999V83.0416C29.4167 80.9583 16.6667 66.9583 16.6667 49.9999ZM74.5417 72.4999C73.4584 69.1249 70.375 66.6666 66.625 66.6666H62.4584V54.1666C62.4584 51.8749 60.5834 49.9999 58.2917 49.9999H33.2917V41.6666H41.625C43.9167 41.6666 45.7917 39.7916 45.7917 37.4999V29.1666H54.125C58.7084 29.1666 62.4584 25.4166 62.4584 20.8333V19.1249C74.6667 24.0416 83.3334 36.0416 83.3334 49.9999C83.3334 58.6666 79.9584 66.5833 74.5417 72.4999Z"
      fill="currentColor"
    />
  </svg>
);
